import React from 'react';
function TermConditions() {
    return (
        <>
            <div className='headerBanner aboutBG'>
                <h1>Terms and Conditions</h1>
            </div>
            <div className='container pageText ptb'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2>Airlines Tickets</h2>
                        <p>Welcome to Cloud Fares ("we," "us," or "our"). These terms and conditions govern your use of our website and services. By accessing or using our website and services, you agree to be bound by these terms. If you do not agree with these terms, please do not use our services.</p>
                        <h3>1. Services and Bookings</h3>
                        <p>We offer travel-related services including flight bookings, hotel reservations, and other travel arrangements ("Services"). By using our website, you confirm that you are of legal age to enter into contracts and will use the Services for legitimate purposes only.</p>
                        <h3>2. Bookings Through Phone</h3>
                        <p>In the unlikely event that you make a reservation over the phone, please provide us with all the necessary details. Please make sure that all of the information you provide is correct and matches the information in your passport. Please let us know if you are using your credit/debit card (under our approval) and if you have their explicit consent to use your credit / debit card.</p>
                        <p>Please provide us with enough funds to cover the cost of the events booked with us. Please note that a phone booking confirmation is just as legally binding as an in-writingbooking. Please keep in mind that we make no representations or guarantees about the availability of any of the holiday packages, flights or individual components. If we receive your reservation in the unlikely event that we do not expect to receive it, we will process it (pending availability). We will then deduct your payment from your account. Please review all of the information on your confirmation/e-tickets/receipt as soon as you get it. Contact us immediately if anything appears to be wrong.</p>
                        <p>If you make any changes to your booking after it is made, you may be charged additional fees, including any applicable supplier fees and administration fees.</p>
                        <h3>3.Deposit Bookings:</h3>
                        <p>If there is a discrepancy between the time of deposit of your flight tickets / travel packages and the time of payment of your balance (i.e. because of increase in airport taxes, increase in government fees, increase in fuel surcharge, seats sold out for a particular contract (original booking) then you will have to pay the difference in order for your ticket to be issued, otherwise it will not be issued at all).All prices are per person and can be changed or withdrawn at any time with no notice. Unless stated otherwise, all prices are per person including VAT.Advertised rates for travel between certain dates may apply. We will check with you at the point of payment to confirm the correct price. Pricing is not guaranteed until complete payment is received. You may need to pay a sum for each person to ensure that your trip plans are safe.</p>
                        <p>All deposits are non-refundable and non-transferable in case you change your mind or cancel your booking. Purchasing online only. We do not offer balance payment reminders.
                            Some airfares and services may not be refundable and you may be required to pay the full amount when booking (some of which are included in the Journeys package). All taxes will be collected. However, in some countries, additional departure tax, hotel tax or other local tax may apply. We recommend tourists carry enough local cash.
                        </p>
                        <h3>4. Booking Modifications and Cancellations</h3>
                        <h4>A.Changes/Re-Issuance:</h4>
                        <p>If you would like to change any of the ticket terms, please contact us by phone or email first if you wish to change your reservation. If a change is allowed, we may need your consent in writing that you wish to change the reservation. All changes are subject to the restrictions, restrictions and availability of the travel supplier. If any change affects the cost of your booking, then you will be responsible for any extra charges. If there are any changes to your booking, please keep in mind that these charges may go up as you get closer to your departure date. If you decide to alter any aspect of your trip plans after receiving our confirmation invoice (i.e., preferred departure date, accommodation), we will do everything in our power to comply with your request. A minimum administration fee of £75 per passenger is set. Any additional charges incurred as a result of the change, including charges charged by your services provider(s), are charged to you as a passenger. Some travel plans cannot be changed once a reservation is processed. Therefore, amendment fees may be up to your vacation price.However, some travel plans cannot be altered or revoked after booking; any alteration will result in a 100% cancellation fee. For example, changes must be communicated to us in writing.</p>
                        <h4>B.Changes by the Supplier/Airlines</h4>
                        <p>For operational reasons, airlines may change departure times or, in exceptional cases, cancel flights. While we are not responsible for and have no control over such changes, we will do our utmost to assist you when this happens. Your entitlements and remedies will depend on the supplier’s/airline’s conditions of carriage. In the unlikely event of your flight being cancelled by the airlines or tour operators, you may be entitled to the following:</p>
                        <p>(a) complimentary transportation on a later flight operated by that airline;<br />
                            (b) complimentary rerouting with a different airline to your destination;<br />
                            (c) a full refund;<br />
                            (d) any other rights or remedies available to you;<br />
                            (e) Reissue or revalidation of your tickets is generally not required in the event that timetable changes are made before the commencement of your journey; however, we will inform you if this is necessary.
                        </p>
                        <p>All information provided on the website is without any warranty or warranty.</p>
                        <h3>5. Travel Documents and Health Requirements</h3>
                        <p>In the event that you are denied entry into any foreign country due to incorrect documentation or health precautions, Cloud Fares shall not be held liable in any way, whether financial or otherwise. Please note that all travellers must have valid passports for all international travel. Most countries require a passport of at least 6 months’ validity as of the date of your return. Some countries also require you to have an international health document and/or an unstamped available page in your passport. You are solely responsible for any fine, penalty, payment, delay or expenditure incurred due to the non-compliance of your documents with the requirements of these authorities (except in the case of fault on the part of Cloud Fares).</p>
                        <p>You are required to pre-register for visa waiver programs in Australia, Canada and the United States if you have registered previously. Please check that your registration is current and has not expired. Please show your child’s full, undrawn birth certificate if you are traveling to South Africa.However, there are additional conditions if the child is traveling alone, single, or without a parent. If the child fails to provide this information during the check-in process, the traveller will be denied boarding.</p>
                        <h3>6. Disclaimers and Limitation of Liability</h3>
                        <ul>
                            <li>We provide information and services on an "as-is" basis and make no warranties, express or implied, regarding accuracy, fitness for purpose, or availability.</li>
                            <li>We are not responsible for any losses, damages, or expenses arising from your use of our Services, including but not limited to booking errors, travel disruptions, or personal injury.</li>

                        </ul>
                        <h3>7. Intellectual Property</h3>
                        <ul>
                            <li>Our website and content are protected by intellectual property laws. You may not reproduce, distribute, or use our content without our permission.</li>
                        </ul>
                        <h3>8. User Responsibilities</h3>
                        <ul>
                            <li>You agree not to use our website for any unlawful or unauthorized purposes, including but not limited to transmitting viruses, spam, or harmful content.</li>
                            <li>You are responsible for protecting your login credentials and ensuring the security of your account.</li>

                        </ul>
                        <h3>9. Links to Third-Party Websites</h3>
                        <ul>
                            <li>Our website may contain links to third-party websites. We are not responsible for the content, privacy policies, or practices of these websites.</li>
                        </ul>
                        <h3>10. Termination</h3>
                        <ul>
                            <li>We reserve the right to terminate or suspend your access to our Services at our discretion, without notice.</li>
                        </ul>
                        <h3>11.Cancellation,Refunds and Changes</h3>
                        <h4>A.Cancellation</h4>
                        <p>An admin fee of £75 will be charged per passenger as a cancellation penalty in the event of pre/ post ticket issuance, on top of the airline penalty. Please contact us via phone/ email first to let us know if the terms of your ticket allow you to request a refund. If not, you will need to let us know in writing.</p>
                        <p>If you cancel before your scheduled departure time or fail to check in, your booking will be recorded as a ‘no show’ and you will be liable to forfeit all monies you have paid.</p>
                        <p>If a refund is allowed, it may take the airline some time (8-16 weeks) to approve the refund. Once approved, the refund will be paid to the debit/credit card you made the original booking with. It is important to let us know when you cancel your booking if your credit card no longer.</p>
                        <h4>B.Refunds</h4>
                        <p>Many airlines charge an extra fee for processing refunds. All refunds will be subject to the usual Cloud Fares administration fees. Booking charges, postage and any previous changes you have paid are also non refundable. If you have booked a non-refundable scheduled flight ticket, you may be able to claim back unused taxes. However, not all taxes can be refunded.</p>
                        <p>Some airlines charge a fee to process such requests. In some cases, the charge is higher than the refund you would receive. Ask for details when cancelling your booking. The service fee portion of a refund is non-refundable. This could be as little as £50 for domestic flights, and as much as £75 for international flights, depending on the airline. No show at the airport if you arrive before the close of check in. These bookings are considered full non-refundable. There may be a waiver, but it is at the airline's discretion.</p>
                        <p>There is no set time frame for a refund to be reflected in your account (in the original form of payment). Refunds are processed according to a process that varies depending on the supplier (consolidator or whole seller) or the airline.Once we receive your request, we work with the consolidator or the airline to generate refunds through waivers dependent on the supplier for the receipt of the requested refunds.Once the refund is approved by the airline, it may take an additional amount of time for it to show up on your card statement. In most cases, it may take 60-90 days to receive credit on your statement.</p>
                        <h4>C.Changes/Re-Issuance</h4>
                        <p>Please call/ email us first to let us know if the terms of your ticket allow you to make any changes.
                            If a change is allowed, we may request that you confirm in writing.</p>
                        <p>Any changes are dependent on availability, restrictions and limitations of the travel supplier(s).
                            If a change increases your booking price, you will have to pay these additional costs.
                            Any changes of any kind are subject to normal administration fees.
                            If, after receiving our confirmation invoice, you want to alter your travel arrangements by any means, such as your selected departure date or your accommodation, please contact us immediately. We will do our utmost to accommodate your request, but may not be able to do so in every case.<br />
                            Please note that these additional costs may increase the closer you are to your departure date, so please contact us atinfo@cloudfares.co.uk.
                        </p>
                        <p>Please note that some travel plans cannot be altered once a booking has been processed, and therefore amendment fees could be as high as the total price of your trip. Some travel plans cannot be modified or cancelled after booking, and any change will result in a full cancellation fee. All changes need to be confirmed in writing to us.</p>
                        <h4>D.Changes by the Suppliers/Airlines</h4>
                        <p>Airlines have the right to change the time of your flight or, in exceptional cases, cancel your flight on operational grounds. While we are not responsible for any such changes and have no control over them, we will try our best to help you in such cases.</p>
                        <p>In the unlikely event of your flight being cancelled by the airlines or tour operators, your entitlements and remedies will depend on the supplier’s conditions/airline’s conditions for carriage.</p>
                        <h4>You may have the right to:</h4>
                        <p>a) be transferred to another flight on the same airline with no extra cost<br />
                            b) be re-routed to your destination by another carrier with no additional cost<br />
                            c) be entitled to a full refund<br />
                            d) Any other entitlement or remedy.
                        </p>
                        <p>If your flight is rescheduled en-route, we do not always require your tickets to be reissued/revalidated. We will advise you if this is necessary. Please note that we do not take any responsibility for any rescheduling of flights en-route.</p>

                        <h3>12. Contact Us</h3>
                        <p>If you have questions or concerns about these terms and conditions, please contact us at info@cloudfares.co.uk</p>
                        <h3>13.Complaints</h3>
                        <p>If you have any issues with your travel arrangements or with any third-party supplier, you must immediately contact the supplier in question. The provider is not responsible for any losses or damages suffered by you under these circumstances.</p>
                        <p>If you cannot resolve any problem directly with the supplier, you may, at your option, seek reasonable assistance with the supplier in order to resolve the problem.</p>
                        <p>To request the provider’s assistance in resolving disputes with a supplier, please email info@cloudfares.co.uk at the latest 28 days after your return.</p>
                        <p>Please include complete details of your dispute and the supplier you are referring to.</p>
                        <p>A copy of any reports received by you regarding the problem, as well as a booking reference number, shall also be included. The above information is intended to represent the entirety of the liability and obligations of the provider in the event that you make a claim against the provider in relation to the services provided by the provider in your booking. Please note that, in the case of a dispute between you and a supplier whose services are included in your booking, all applicable terms and conditions shall apply.</p>
                        <h3>14.Insurance</h3>
                        <p>You are required to have adequate travel insurance under your contract with us or, where appropriate, with the supplier. You need to take out a travel insurance policy to protect you and your loved ones from the costs of cancellation by yourself; the costs of assistance, including repatriation, in case of an accident or sickness; loss of luggage and cash; and other costs.</p>
                        <p>If we have issued you a travel insurance policy, please review it carefully to make sure that all the information is correct and that you have provided all the necessary information (such as pre-existing health conditions). If you do not provide relevant information, your insurance will be affected.
                            If you do not travel with adequate cover, we are not responsible for any losses that would have occurred if you travelled with adequate cover.
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TermConditions
