import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import homeSlider1 from "../images/slider/homeSlider1.jpg";
import homeSlider2 from "../images/slider/homeSlider2.jpg";
import homeSlider3 from "../images/slider/homeSlider3.jpg";
import homeSlider4 from "../images/slider/homeSlider4.jpg";
import Price from "../images/icon/PriceMatch_logo.png";
import Deposit from "../images/icon/LowDeposit_icon.png";
import Service from "../images/icon/Customer_icon.png";
import Secure from "../images/icon/Online_icon.png";
import SearchEng from '../Include/SearchEng';
import AirlineSlider from '../Include/AirlineSlider';
import PopularDestinations from '../Destinations/PopularDestinations';
import Subscribe from '../Include/Subscribe';
import ContinentsHome from '../Destinations/ContinentsHome';
function Home() {

  return (
    <>
      <div className='container-fluid p0'>
        <div className='row'>
          <div className='col-md-12 homeSearch'>
            <Carousel>

              <Carousel.Item>
                <img src={homeSlider1} alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={homeSlider2} alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={homeSlider3} alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={homeSlider4} alt="" />
              </Carousel.Item>

            </Carousel>
            {/* <div className='container myflight'>
              <SearchEng />

            </div> */}
          </div>
        </div>
      </div>
      <section className="dissave">
        <div className="container">


          <div className="title_heading">
            <h3> Explore, discover   <strong>and save</strong></h3>
          </div>
          <div className="row">
            <div className="col-md-3"><div className="iconbg"><div className='imgIcon'><img src={Price} alt="" /></div><h2>PRICE MATCH<br />POLICY</h2></div></div>
            <div className="col-md-3"><div className="iconbg"><div className='imgIcon'><img src={Secure} alt="" /></div><h2>SECURE ONLINE <br />TRANSACTION</h2></div></div>
            <div className="col-md-3"><div className="iconbg"><div className='imgIcon'><img src={Service} alt="" /></div><h2>24/7 CUSTOMER <br />SERVICE</h2></div></div>
            <div className="col-md-3"><div className="iconbg"><div className='imgIcon'><img src={Deposit} alt="" /></div><h2>BOOK WITH LOW <br />DEPOSIT</h2></div></div>
          </div>
        </div>
      </section>
      <div className="popular-cities-cover">
        <div className="container">
          <div className="heading-seciton">
            <h2>Popular Destinations</h2>
            <h5>Explore Popular Destinations</h5>

          </div>
        </div>
        <div className="container">
          <PopularDestinations />
        </div>
      </div>

      {/* <AirlineSlider /> */}
      <ContinentsHome />
   
      <Subscribe />
      <div className='container headerPage'>
        <div className='row'>
          <div className='col-md-12'>
            <h2>About Cloud Fares</h2>
           
          </div>
        </div>
        <div className='row'>

          <div className='col-md-12'>
          <p>At Cloud Fares, we believe that the world is meant to be explored, and we're here to make your travel dreams a reality. With a passion for discovery and a commitment to excellence, we're your trusted partner in crafting personalized journeys that leave you with cherished memories.<Link to="/about-us"> Read More...</Link></p>
          </div>

        </div>
      </div>
    </>
  )
}
export default Home;
