import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import holidayFlight from "../images/img/holidayFlight.jpg";
export default function Thanks() {
    return (
        <>
     <section className="thankspage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
            <div className='no-results'>
             <div className='CalltoBook'>
    <h1>Thank You.!</h1>
    <p>We have received your enquiry and one of our travel expert will get in touch with you very soon.</p>
    <Link to="/"><img src={holidayFlight} alt="Airlines Tickets" /></Link>
    <h3>Contact Us</h3>
    <p>Got a question or need assistance? Our friendly team is here to help!</p>
 
    <h2><strong>Customer Support: </strong><a href='tel: +44 0208 095 2365'>+44 0208 095 2365 </a><br /> 
    <strong>Email: </strong> <a href='mailto:info@cloudfares.co.uk'>info@cloudfares.co.uk</a>
</h2>
    </div>

            </div>
  
            </div>
          </div>
        </div>
      </section>
       
        </>
    )
}
