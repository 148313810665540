import React from 'react'

function Contact() {
    return (
        <>
            <div className='headerBanner contactHeader'>
                <h1>Contact Us</h1>
            </div>
            <div className="container contactpagem">
                <div className="contact-us-main contactpage">
                    <div className="row">
                        <div className="col-xl-6 contactfr">
                            <div className="contact-us-form-main">
                                <div className="heading">
                                    <h2>Get in Touch!</h2>
                                </div>
                                <form action="https://cloudfares.co.uk/wait.php" method="post" autocomplete="off" className="form">

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-input">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name='cname' placeholder="Name" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-input">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" name='cemail' placeholder="Email.." required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-input">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name='xx_Sb' placeholder="Subject" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-input">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name='callno' placeholder="Phone No." required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <div className="input-group">
                                                    <textarea className="form-control textarea" name="cMessage" placeholder="Messages"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-input">
                                                <div className="btn-submit">
                                                    <button type="submit" className="btn" name="contactus" value="Send Message"> send message </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-6">

                            <div className="contact-info">
                                {/* <h3>Address</h3> */}
                                <ul>
                                    {/* <li> <i className="fa fa-map-marker"></i>
                                    128 City Road,<br />
                                    London, EC1V 2NX
                                    </li> */}
                                    <li> <i className="fa fa-paper-plane"></i> <a href="mailto:info@cloudfares.co.uk">info@cloudfares.co.uk</a> </li>
                                    <li> <i className="fa fa-tty"></i> <a href="tel:+44 0208 095 2365
">+44 0208 095 2365 </a></li>
                                </ul>
                                <div className="opening-hrs">
                                    <h4>Opening Hours</h4>
                                    <p><span>Monday to Sunday 8am - 11pm</span></p>
                                    
                                </div>

                            </div>



                        </div>
                    </div>

                </div>




            </div>

        </>
    )
}

export default Contact
