import React, { useState } from 'react';
import Select from 'react-select'; // Import the react-select library
import { useNavigate  } from 'react-router-dom'; // Import useNavigate  from react-router-dom
import axios from 'axios';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import FromAirport from './FromAirport';
import ToAirport from './ToAirport';

const SearchEng = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [source, setSource] = useState('');
    const [destination, setDestination] = useState('');
    const Navigate  = useNavigate (); // Use useNavigate  hook

    const handleSourceChange = (selectedSource) => {
      const iataCode = selectedSource.value;
      setSource(iataCode);
    };
  
    const handleDestinationChange = (selectedDestination) => {
      const iataCode = selectedDestination.value;
      setDestination(iataCode);
    };
  

    const handleSearch = (e) => {
      e.preventDefault();
  
      const apiUrl = `https://peonytrips.com/FlightFare/FlightFareAPI?Source=${source}&Destination=${destination}`;
  
      axios.get(apiUrl)
        .then(response => {
          Navigate ('/SearchFlight', { state: response.data }); // Navigate  to FlightSearch page with flight data
        })
        .catch(error => {
          console.error('Error fetching flight data:', error);
        });
    };
    return (
      <>
       
       <div className="col-md-12">
       <form onSubmit={handleSearch} autoComplete='off'>
    <div className="row">
     <div className="col-md-3">
        <div className="form-group m0">
          <label>Depart from *</label>
          {/* <input
          type="text" className='form-control'
          value={source}
          onChange={(e) => setSource(e.target.value)}
        /> */}
        
         <FromAirport onChange={handleSourceChange} />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group m0">
          <label>Arrive at *</label>
          {/* <input
          type="text" className='form-control'
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
        /> */}
          {/* <ToAirport /> */}
          <ToAirport onChange={handleDestinationChange} />
      
        </div>
      </div>
      <div className="col-md-3 w50">
        <div className="form-group calIcon">
          <label>Leave on * </label>         
          <DatePicker required className="form-control " name="DepartDate"
               selected={startDate}
               onChange={(date) => setStartDate(date)}
               minDate={new Date()}
             
               showDisabledMonthNavigation
               selectsStart
               startDate={startDate}
               endDate={endDate}
                dateFormat='dd/MM/yyyy'
              
            />
        </div>
      </div>
      <div className="col-md-3 w50">
        <div className="form-group">
          <label>Return On *</label>          
          <DatePicker required className="form-control calIcon" name="ReturnDate"
                 selected={endDate}
                 onChange={(date) => setEndDate(date)}
                 selectsEnd
                 startDate={startDate}
                 endDate={endDate}
                 minDate={startDate}                
                dateFormat='dd/MM/yyyy' 
                
            />
        </div>
      </div>

      <div className="col-md-2 w30">
        <div className="form-group">
          <label>Adults</label>
          <select name="Adults" id="Adults" className="form-control">
            <option value="1" selected="selected">1 Adult</option>
            <option value="2">2 Adults</option>
            <option value="3">3 Adults</option>
            <option value="4">4 Adults</option>
            <option value="5">5 Adults</option>
            <option value="6">6 Adults</option>
            <option value="7">7 Adults</option>
            <option value="8">8 Adults</option>
            <option value="9">9 Adults</option>
          </select>
        </div>
      </div>
      <div className="col-md-2 w30">
        <div className="form-group">
          <label>Children </label>
          <select name="Children" id="Children" className="form-control">
            <option value="0" selected="selected">0 Child</option>
            <option value="1">1 Child</option>
            <option value="2">2 Children</option>
            <option value="3">3 Children</option>
            <option value="4">4 Children</option>
            <option value="5">5 Children</option>
            <option value="6">6 Children</option>
            <option value="7">7 Children</option>
            <option value="8">8 Children</option>
            <option value="9">9 Children</option>
          </select>
        </div>
      </div>
      <div className="col-md-2 w30">
        <div className="form-group">
          <label>Infant </label>
          <select name="Infants" id="Infants" className="form-control">
            <option value="0" selected="selected">0 Infant</option>
            <option value="1">1 Infant</option>
            <option value="2">2 Infant</option>
            <option value="3">3 Infant</option>
            <option value="4">4 Infant</option>
            <option value="5">5 Infant</option>
            <option value="6">6 Infant</option>
            <option value="7">7 Infant</option>
            <option value="8">8 Infant</option>
            <option value="9">9 Infant</option>
          </select>
        </div>
      </div>
      
      <div className="col-md-3">
        <div className="form-group">
          <label>Class</label>
          <select name="Class" id="Class" className="form-control">
          <option value="Economy" selected="selected">Economy</option> 
            <option value="Business">Business</option>                        
          </select>
        </div>
      </div>
      <div className="col-md-3">
      <button type='submit'  name="SearchFlight" className="btn btn-primary flightBTN"><i className="fa fa-plane"></i> Get Best Prices</button>
       
      </div>
    </div>
  </form>
</div>
</>
  );
};

export default SearchEng;