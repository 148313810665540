import React from 'react';
import { Link } from 'react-router-dom';
import AtlantaHome from '../images/Destinations/AtlantaHome.png';
import BangkokHome from '../images/Destinations/BangkokHome.png';
import JohannesburgHome from '../images/Destinations/JohannesburgHome.png';
import lagoshome from '../images/Destinations/lagoshome.png';
import manilaHome from '../images/Destinations/manilaHome.png';
import TorontoHome from '../images/Destinations/TorontoHome.png';

function PopularDestinations() {
  return (
    <>
     <div className="popular-cities-main"> 
       
       <div className="row">
         <div className="col-lg-4 col-sm-6">
           <div className="detail-box position-relative mb-4">
             <div className="detail-img"> <img src={manilaHome} alt="" className="img-fluid" /></div>
             <div className="detail-content">
               <h3>Manila</h3>
               <h6>Cheap Flight to Manila</h6>
               {/* <h3>£ 495.00</h3>
               <span>Per Person</span>*/} </div> 
               {/* <Link to="destinations/Asia/Phillippines"></Link>*/} </div> 
         </div>
         <div className="col-lg-4 col-sm-6">
           <div className="detail-box position-relative mb-4">
             <div className="detail-img"><img src={JohannesburgHome} alt="" className="img-fluid" /> </div>
             <div className="detail-content">
               <h3>Johannesburg</h3>
               <h6>Cheap Flight to Johannesburg</h6>
               {/* <h3>£ 465.00</h3>
               <span>Per Person</span>*/} </div> 
               {/* <Link to="destinations/Africa/South Africa"></Link>*/} </div> 
         </div>
         <div className="col-lg-4 col-sm-6">
           <div className="detail-box position-relative mb-4">
             <div className="detail-img"> <img src={lagoshome} alt="" className="img-fluid" /> </div>
             <div className="detail-content">
               <h3>Lagos</h3>
               <h6>Cheap Flight to Lagos</h6>
               {/* <h3>£ 385.00</h3>
               <span>Per Person</span> */} </div>
               {/* <Link to="destinations/Africa/Nigeria"></Link> */}</div>
         </div>
         <div className="col-lg-4 col-sm-6">
           <div className="detail-box position-relative mb-4">
             <div className="detail-img"> <img src={AtlantaHome} alt="" className="img-fluid" /> </div>
             <div className="detail-content">
               <h3>Atlanta</h3>
               <h6>Cheap Flight to Atlanta </h6>
               {/* <h3>£ 425.00</h3>
               <span>Per Person</span> */} </div>
               {/* <Link to="destinations/North America/United States"></Link>*/} </div> 
         </div>
         <div className="col-lg-4 col-sm-6">
           <div className="detail-box position-relative mb-4">
             <div className="detail-img"> <img src={TorontoHome} alt="" className="img-fluid" /> </div>
             <div className="detail-content">
               <h3>Toronto</h3>
               <h6>Cheap Flight to Toronto</h6>
               {/* <h3>£ 385.00</h3>
               <span>Per Person</span>*/} </div> 
               {/* <Link to="destinations/North America/Canada"></Link>*/} </div> 
         </div>
         <div className="col-lg-4 col-sm-6">
           <div className="detail-box position-relative mb-4">
             <div className="detail-img"><img src={BangkokHome} alt="" className="img-fluid" /> </div>
             <div className="detail-content">
               <h3>Bangkok</h3>
               <h6>Cheap Flight to Bangkok</h6>
               {/* <h3>£ 405.00</h3>
               <span>Per Person</span> */} </div>
               {/* <Link to="destinations/Asia/Thailand"></Link> */} </div>
         </div>
       
       </div>
     </div> 
    </>
  )
}

export default PopularDestinations
