import React from 'react'

function PrivacyPolicy() {
    return (
        <>
            <div className='headerBanner'>
                <h1>Privacy Policy</h1>
            </div>
            <div className='container pageText ptb'>
                <div className='row'>
                    <div className='col-md-12'>
                        <p>Welcome to Cloud Fares("we," "us," or "our"). This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our website and services. By accessing or using our website or services, you consent to the practices described in this Privacy Policy.</p>
                        <h3>1.Booking Online:</h3>
                        <p>Once you have signed up to book on our website, Cloud Fares, some of your personal information will be collected for the purpose of processing your booking and providing our suppliers so that you can complete your booking. You will be required to confirm that you have the authorisation or power to do so. Passenger names, contact numbers, email addresses, physical mailing addresses etc. will be collected as this may enable us to use that information in accordance with this privacy policy.</p>
                        <h3>2. Information We Collect</h3>
                        <p>We collect various types of information to provide and improve our services:</p>
                        <ul>
                            <li>Personal Information: We collect your name, contact details, and payment information when you make a booking or inquire about our services.</li>
                            <li>Travel Information: We collect information about your travel preferences, including flight details, accommodation preferences, and any special requirements you provide.</li>
                            <li>Usage Data: We collect data about your interactions with our website, such as pages visited, search queries, and referral sources.</li>
                            <li>Device Information: We collect information about the device and browser you use to access our services, including IP address, browser type, and operating system.</li>

                        </ul>

                        <h3>3. How We Use Your Information</h3>
                        <p>We use your information for the following purposes:</p>
                        <ul>
                            <li>Booking and Service Delivery: To process and manage your travel bookings, send confirmations, and provide related services.</li>
                            <li>Communication: To respond to your inquiries, provide customer support, and send transactional and administrative emails.</li>
                            <li>Personalization: To tailor our services and recommendations based on your preferences and travel history.</li>
                            <li>Analytics: To analyse user behaviour, improve our website's functionality, and optimize user experience.</li>

                        </ul>
                        <h3>4.Payment Details:</h3>
                        <p>To book any travel-related service online or offline, credit/debit card details are required to complete the booking transaction. Once the transaction is complete, these card details are automatically erased from our database and servers.However, in some cases, we may call to confirm the payment information for the verified transaction.</p>
                        <p>Card details are extremely sensitive information, and we never advise our customers to share them via email or any other means to ensure their privacy.</p>
                        <h3>5. Data Retention</h3>
                        <p>We retain your information for as long as necessary to fulfil the purposes outlined in this policy, unless a longer retention period is required by law.</p>
                        <h3>6. Cookies and Tracking Technologies</h3>
                        <p>We use cookies from Google, Bing, Yahoo, Facebook, Twitter, YouTube etc. to deliver our services and information. These cookies are used to personalise ads and to analyse real time web traffic. By using our website, you agree to use cookies that may be shared with Google or other ad platforms.</p>
                        <p>In order to ensure the best delivery of our services, cookies will be enabled automatically when you visit our website Cloud Fares. This means that you will automatically get one or more cookies when you use our services.We have summarised the essential information about cookies below. You can also learn more about how you can accomplish on your own.</p>
                        <p>Third party cookies from advertisers are also used to analyse user performance according to their cookie policies.</p>
                        <h3>7. Data Security</h3>
                        <p>We implement technical and organizational measures to protect your information from unauthorized access, disclosure, or destruction.</p>
                        <h3>8. Changes to this Policy</h3>
                        <p>We may update this Privacy Policy to reflect changes in our practices or applicable laws. The "Last Updated" date indicates the most recent revision.</p>
                        <h3>9.Third Party Link:</h3>
                        <p>From time to time, the provider may mistakenly load some or all of your data (including allocation, fares, airport taxes, etc.). The provider will promptly inform you of any incorrect data. You have the option of cancelling with full refund, taking the service at its corrected price, or choosing another service (payment of any additional amounts owed). If you are no longer able to take a service, you may choose another service, again payment of any amounts owed. Links to other websites are provided for your convenience only and do not represent or warranty the content or materials on those websites. The provider, its third-party providers or distributors shall not be liable for any content on those other websites and shall not make any representation or warranty concerning any other websites. Thank you for visiting Cloud Fares. We value your privacy and we are committed to providing you with the best experience possible.</p>
                        <h3>10.IP Address</h3>
                        <p>We use cookies from Google, other search engines and advertising platforms to deliver effective services, personalise advertisements and analyse real-time web traffic. By using our website, you agree to the use of cookies which may be shared with Google or other advertising platforms. To track the devices, browsers utilised and geographical locations from which customers and visitors navigate to our website, we may collect the Internet Protocol (‘IP’) address to collect non-personal information.</p>
                        <p>When visiting our website, using any of our mobile apps, or engaging in open communications from us, our servers may record information about your device and network used to connect with us, including your IP address.</p>
                        <h3>11. Contact Us</h3>
                        <p>If you have questions or concerns about this Privacy Policy, please contact us at info@cloudfares.co.uk</p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
