import React from 'react';
import Aboutus from "../images/img/aboutUs.jpg";
function About() {
    return (
        <>
            <div className='headerBanner aboutBG'>
                <h1>About Us</h1>
            </div>
            <div className='container pageText ptb'>
                <div className='row'>
                    <div className='col-md-8'>
                        <h2>About Us</h2>
                        <p>Welcome to Cloud Fares, your passport to seamless and unforgettable travel experiences.</p>
                        <p>At Cloud Fares, we believe that the world is meant to be explored, and we're here to make your travel dreams a reality. With a passion for discovery and a commitment to excellence, we're your trusted partner in crafting personalized journeys that leave you with cherished memories.</p>

                        <h3>Our Mission</h3>
                        <p>AtCloud Fares, our mission is simple yet profound: to inspire and enable travellers to embark on journeys that transcend the ordinary. We're dedicated to curating travel experiences that go beyond the destination, enriching your life and broadening your horizons. Your journey is our canvas, and we're here to paint it with the colours of adventure, culture, and connection.</p>
                        <h3>Our Values</h3>
                        <ul>
                            <li><strong>Customer-Centric:</strong> Your satisfaction is our North Star. We're driven by a commitment to understanding your unique desires and needs, tailoring every aspect of your trip to ensure it aligns perfectly with your aspirations.</li>
                            <li><strong>Innovation:</strong> In the world of travel, possibilities are boundless. We embrace technology and innovation to provide you with cutting-edge solutions, seamless booking experiences, and up-to-the-minute travel information.</li>
                            <li><strong>Integrity:</strong> We believe in transparent and ethical practices. You can trust us to provide you with accurate information, fair pricing, and unwavering support throughout your journey.</li>
                        </ul>
                        <h3>What We Offer</h3>
                        <p>Cloud Faresoffers a diverse range of travel services designed to suit your preferences and interests:</p>
                        <ul>
                            <li><strong>Customized Itineraries:</strong> Our team of experienced travel experts crafts tailor-made itineraries that match your travel style and preferences, ensuring every moment of your trip is meaningful.</li>
                            <li><strong>Global Destinations:</strong> Whether you're yearning for the tranquil beaches of Bali, the bustling streets of Tokyo, or the romantic ambiance of Paris, we have your dream destinations covered.</li>
                            <li><strong>Travel Resources:</strong> Our website is your gateway to a wealth of travel resources, from destination guides and travel tips to packing lists and cultural insights.</li>
                            <li><strong>24/7 Support:</strong> Your journey doesn't stop just because the sun sets. Our dedicated support team is available around the clock to assist you, ensuring peace of mind every step of the way.</li>
                        </ul>
                        <h3>Your Journey Starts Here</h3>
                        <p>Cloud Faresis more than an online travel agency; we're your companions in exploration. With years of experience and a passion for uncovering the world's hidden gems, we're excited to be part of your travel story. Let's embark on a journey together, where the possibilities are as limitless as the horizons.</p>
                        <p>For inquiries, bookings, and to learn more about our services, reach out to us at info@cloudfares.co.uk</p>
                    </div>
                    <div className='col-md-4'>
                        <img src={Aboutus} alt="About Us" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default About
