import React from 'react'
import { Accordion } from "react-bootstrap";
function FAQ() {
  return (
    <>
      <div className='headerBanner'>
        <h1>FAQ</h1>
      </div>
      <div className="container pageText ptb">
        <div className="row">
          <div className="col-md-12">
            <h2>Frequently Asked Questions</h2>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How do I make a booking withCloud Fares?</Accordion.Header>
                <Accordion.Body><p>Booking with us is easy! You can visit our website and use our user-friendly booking engine to search for your desired destination, travel dates, and preferences. Once you find the perfect options, simply follow the prompts to complete your booking securely.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can I modify or cancel my booking after it's been made?</Accordion.Header>
                <Accordion.Body>
                  <p>Yes, you can modify or cancel your booking, but it's important to review our modification and cancellation policies for each specific booking. Please note that fees and restrictions may apply.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What payment methods do you accept?</Accordion.Header>
                <Accordion.Body>
                  <p>We accept a variety of payment methods, including major credit cards and online payment platforms. The accepted payment methods will be displayed during the booking process.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Do you offer travel insurance?</Accordion.Header>
                <Accordion.Body>
                  <p>Yes, we offer travel insurance to provide you with peace of mind during your journey. Our travel insurance options cover various aspects, including trip cancellations, medical emergencies, and more. Contact us for more information. </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Are your prices inclusive of taxes and fees?</Accordion.Header>
                <Accordion.Body>
                  <p>Our listed prices typically include the base fare and some standard fees. However, some additional fees, such as airport taxes or resort fees, may not be included in the initial price. Please review the details of your booking for a breakdown of costs.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>How do I receive my travel documents and itinerary?</Accordion.Header>
                <Accordion.Body>
                  <p>Once your booking is confirmed and payment is processed, you will receive an email containing your travel itinerary and booking confirmation. You can either print these documents or have them accessible on your mobile device.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>What happens if my flight is delayed or cancelled?</Accordion.Header>
                <Accordion.Body>
                  <p>In case of flight delays or cancellations, we recommend contacting the airline directly for immediate assistance. If you have booked through us and experience any issues, feel free to reach out to our customer support team for guidance.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Can I request special accommodations, such as dietary restrictions or accessibility needs?</Accordion.Header>
                <Accordion.Body>
                   
                  <p>Absolutely! During the booking process, there's usually an option to specify any special requests or requirements you may have. We will do our best to accommodate your needs and communicate them to the relevant service providers.</p>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>Do you offer group travel or corporate booking services?</Accordion.Header>
                <Accordion.Body>
                  <p>Yes, we offer group travel and corporate booking services. If you're traveling with a group or require assistance with corporate travel arrangements, please contact us for personalized assistance and group rates.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>How can I reach your customer support team?</Accordion.Header>
                <Accordion.Body>
                  <p>You can reach our customer support team by phone at 0208 095 2365 or by email at info@cloudfares.co.uk. Our team is available during our business hours to assist you with any inquiries or issues you may have.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>What safety measures are in place due to COVID-19?</Accordion.Header>
                <Accordion.Body>
                  <p>We prioritize your safety and follow all relevant guidelines and recommendations related to COVID-19. Please visit our website or contact us for information about our current safety protocols and travel advisories.</p>
                </Accordion.Body>
              </Accordion.Item>
             

            </Accordion>
          </div>
        </div>
      </div>
    </>
  )
}

export default FAQ
