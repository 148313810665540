import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Carousel from 'react-bootstrap/Carousel';
import continents from "../images/img/continents.jpg";
import SearchEng from '../Include/SearchEng';

function Country() {
  const { continentName } = useParams();
  const [cheapestEconomyPrices, setCheapestEconomyPrices] = useState({});
  const [countryImages, setCountryImages] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    fetch(`https://peonytrips.com/FlightFare/DestinationsAPI?continent=${continentName}`)
      .then(response => response.json())
      .then(data => {
        const filteredEconomyDestinations = data.filter(
          destination => destination.Continents === continentName && destination.Class === 'Economy'
        );

        const cheapestPricesByCountry = {};
        const imageUrlsByCountry = {};

        filteredEconomyDestinations.forEach(destination => {
          const country = destination.ToCountry;
          const airport = destination.ToAirportName;
          const price = destination.Price;
          const imageUrl = destination.CountryImg;

          if (!cheapestPricesByCountry[country]) {
            cheapestPricesByCountry[country] = [];
          }

          cheapestPricesByCountry[country].push({ airport, price });
          imageUrlsByCountry[country] = imageUrl;
        });

        for (const country in cheapestPricesByCountry) {
          cheapestPricesByCountry[country].sort((a, b) => a.price - b.price);
        }
        setCheapestEconomyPrices(cheapestPricesByCountry);
        setCountryImages(imageUrlsByCountry);
        setIsLoading(false); // Set loading state to false after data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading state to false in case of an error
      });
  }, [continentName]);

  // Render a loading indicator when data is being fetched
  if (isLoading) {
    return <div className='dataloading'><img src='https://www.airlines-ticketsdesk.com/popup/dataload.gif' /></div>;
  }

  // Render content when data is fetched
  return (
    <>
        <div className='container-fluid p0'>
        <div className='row'>
          <div className='col-md-12 homeSearch'>
            <Carousel>
              <Carousel.Item>
                <img src={continents} alt="" />
              </Carousel.Item>

            </Carousel>
            {/* <div className='container myflight'>
              <SearchEng />

            </div> */}
          </div>
        </div>
      </div>

      <div className='container headerPage'>
        <div className='row'>
          <div className='col-md-12'>
            <h2>{continentName} / Countries</h2>
            <h3>Explore Multiple Destinations</h3>
          </div>
        </div>

        <div className='row destination-cover'>
        {Object.entries(cheapestEconomyPrices).map(([country, airports]) => (
  <div className='col-md-3' key={country}>
    {/* <Link to={`/destinations/${continentName}/${encodeURIComponent(country)}`}> */}
      <div className="continents-box position-relative">
        <img className="img-fluid" src={countryImages[country]} alt={country} />
        <div className="continent-info">
          <h3>{ReactHtmlParser(country)}</h3>
          <p>Book With Low Prices for {ReactHtmlParser(country)}</p>
          <div className="price-countries">
          <a href="tel:+44 0208 095 2365"> Call Us  <i class="fa fa-phone"></i> +44 0208 095 2365</a>
            {/* {airports.length > 0 && ( // Check if there are airports for the country
              <span><sub>£</sub> {airports[0].price}</span>
            )} */}
          </div>
        </div>
      </div>
    {/* </Link> */}
  </div>
))}
        </div>
      </div>
    </>
  );
}

export default Country;
