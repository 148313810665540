import React from 'react'
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import SearchBanner from "../images/airlineBanner/airlineBanner.jpg";
import AirlineSearchEng from '../Include/AirlineSearchEng';
import AC from '../images/Airline/AC.png';
import AF from '../images/Airline/AF.png';
import AI from '../images/Airline/AI.png';
import AT from '../images/Airline/AT.png';
import BA from '../images/Airline/BA.png';
import CX from '../images/Airline/CX.png';
import EK from '../images/Airline/EK.png';
import ET from '../images/Airline/ET.png';
import EY from '../images/Airline/EY.png';
import GF from '../images/Airline/GF.png';
import IB from '../images/Airline/IB.png';
import JL from '../images/Airline/JL.png';
import KL from '../images/Airline/KL.png';
import KQ from '../images/Airline/KQ.png';
import KU from '../images/Airline/KU.png';
import LH from '../images/Airline/LH.png';
import ME from '../images/Airline/ME.png';
import MH from '../images/Airline/MH.png';
import MK from '../images/Airline/MK.png';
import MS from '../images/Airline/MS.png';
import QF from '../images/Airline/QF.png';
import QR from '../images/Airline/QR.png';
import RJ from '../images/Airline/RJ.png';
import SN from '../images/Airline/SN.png';
import SQ from '../images/Airline/SQ.png';
import SV from '../images/Airline/SV.png';
import TG from '../images/Airline/TG.png';
import TK from '../images/Airline/TK.png';
import TP from '../images/Airline/TP.png';
import UA from '../images/Airline/UA.png';
import UL from '../images/Airline/UL.png';
import VS from '../images/Airline/VS.png';
import WB from '../images/Airline/WB.png';
import WS from '../images/Airline/WS.png';
import WY from '../images/Airline/WY.png';
import AA from '../images/Airline/AA.png';
function Airlines() {
    return (
        <>
            <div className='container-fluid p0'>
                <div className='row'>
                    <div className='col-md-12 homeSearch'>
                        <Carousel>
                                <Carousel.Item>
                                <img src={SearchBanner} alt="" />
                            </Carousel.Item>

                        </Carousel>
                        <div className='container'>
                            <AirlineSearchEng />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container headerPage mobileAirlineLogo'>
                <div className='row'>
                    <div className='col-md-12'><h2>Select and Book Best Airlines Tickets With Us. </h2><h3>Select Airline of Your Choice to Fly With Us
                    </h3></div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <Link to="/airlines/air-canada">
                            <div className='airlinelogo'>
                                <img src={AC} alt="AC" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/air-france">
                            <div className='airlinelogo'>
                                <img src={AF} alt="AF" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/air-india">
                            <div className='airlinelogo'>
                                <img src={AI} alt="AI" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/royal-air-maroc-airline">
                            <div className='airlinelogo'>
                                <img src={AT} alt="AT" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/british-airways">
                            <div className='airlinelogo'>
                                <img src={BA} alt="BA" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/cathay-pacific">
                            <div className='airlinelogo'>
                                <img src={CX} alt="CX" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/emirates">
                            <div className='airlinelogo'>
                                <img src={EK} alt="EK" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/ethiopian-airlines">
                            <div className='airlinelogo'>
                                <img src={ET} alt="ET" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/etihad-airways">
                            <div className='airlinelogo'>
                                <img src={EY} alt="EY" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/gulf-air">
                            <div className='airlinelogo'>
                                <img src={GF} alt="GF" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/iberia-airline">
                            <div className='airlinelogo'>
                                <img src={IB} alt="IB" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/japan-airlnes">
                            <div className='airlinelogo'>
                                <img src={JL} alt="JL" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/klm-airline">
                            <div className='airlinelogo'>
                                <img src={KL} alt="KL" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/kenya-airways">
                            <div className='airlinelogo'>
                                <img src={KQ} alt="KQ" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/kuwait-airways">
                            <div className='airlinelogo'>
                                <img src={KU} alt="KU" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/lufthansa-airline">
                            <div className='airlinelogo'>
                                <img src={LH} alt="LH" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/middleeast-airlines">
                            <div className='airlinelogo'>
                                <img src={ME} alt="ME" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/malaysian-airlines">
                            <div className='airlinelogo'>
                                <img src={MH} alt="MH" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/air-mauritius">
                            <div className='airlinelogo'>
                                <img src={MK} alt="MK" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/egypt-air">
                            <div className='airlinelogo'>
                                <img src={MS} alt="MS" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/qantas-airways">
                            <div className='airlinelogo'>
                                <img src={QF} alt="QF" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/qatar-airways">
                            <div className='airlinelogo'>
                                <img src={QR} alt="QR" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/royal-jordanian-airlines">
                            <div className='airlinelogo'>
                                <img src={RJ} alt="RJ" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/brussels-airlines">
                            <div className='airlinelogo'>
                                <img src={SN} alt="SN" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/singapore-airlines">
                            <div className='airlinelogo'>
                                <img src={SQ} alt="SQ" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/saudi-arabian-airlines">
                            <div className='airlinelogo'>
                                <img src={SV} alt="SV" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/thai-airways">
                            <div className='airlinelogo'>
                                <img src={TG} alt="TG" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/turkish-airlines">
                            <div className='airlinelogo'>
                                <img src={TK} alt="TK" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/tap-air-portugal">
                            <div className='airlinelogo'>
                                <img src={TP} alt="TP" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/united-airlines">
                            <div className='airlinelogo'>
                                <img src={UA} alt="UA" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/srilankan-airlines">
                            <div className='airlinelogo'>
                                <img src={UL} alt="UL" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/virgin-atlantic">
                            <div className='airlinelogo'>
                                <img src={VS} alt="VS" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/rwandair">
                            <div className='airlinelogo'>
                                <img src={WB} alt="WB" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/westjet-airlines">
                            <div className='airlinelogo'>
                                <img src={WS} alt="WS" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/oman-air">
                            <div className='airlinelogo'>
                                <img src={WY} alt="WY" />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to="/airlines/american-airlines">
                            <div className='airlinelogo'>
                                <img src={AA} alt="AA" />
                            </div>
                        </Link>
                    </div>
                     

                </div>
            </div>
        </>
    )
}

export default Airlines
