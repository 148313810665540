import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import continents from "../images/img/continents.jpg";
import SearchEng from '../Include/SearchEng';

function Continents() {
  const [destinationsByContinent, setDestinationsByContinent] = useState({});
  const [continentImages, setContinentImages] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Fetch data from the API
    fetch('https://peonytrips.com/FlightFare/DestinationsAPI')
      .then(response => response.json())
      .then(data => {
        const destinationsByContinent = {};
        const continentImages = {};

        data.forEach(destination => {
          const continent = destination.Continents;
          const country = destination.ToCountry;

          if (!destinationsByContinent[continent]) {
            destinationsByContinent[continent] = new Set();
          }
          destinationsByContinent[continent].add(country);

          continentImages[continent] = destination.ContinentsImg;
        });

        setDestinationsByContinent(destinationsByContinent);
        setContinentImages(continentImages);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);

  // Render a loading indicator when data is being fetched
  if (loading) {
    return <div className='dataloading'><img src='https://www.airlines-ticketsdesk.com/popup/dataload.gif' /></div>;
  }

  // Render content when data is fetched
  return (
    <>
  
      <div className='container headerPage p0 mytest'>
        <div className='row'>
          <div className='col-md-12'>
            <h2>Explore Beautiful Continents</h2>
            <h3>Fly your desired destinations with budget-friendly deals</h3>
          </div>
        </div>
        <div className='row destination-cover'>
          {Object.keys(destinationsByContinent).map(continent => (
            <div className='col-md-3' key={continent}>
              <Link to={`/destinations/${continent}`}>
                <div className="continents-box position-relative">
                  <img className="img-fluid" src={continentImages[continent]} alt={continent} />
                  <div className="continent-info">
                    <h3>{continent}</h3>
                    <p>Explore {continent} With Best Flight Deals</p>
                    <span>View More <i className="fa fa-arrow-right"></i></span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Continents;
