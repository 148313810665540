import React from 'react'

function PriceMatchPolicy() {
  return (
    <>
      <div className='headerBanner'>
        <h1>Price Match Policy</h1>
      </div>
      <div className='container pageText ptb'>
        <div className='row'>
          <div className='col-md-12'>
            <p>At Cloud Fares, we are committed to providing you with the best travel deals and exceptional service. Our Price Match Policy ensures that you get the most value for your money while booking your dream vacations. We're dedicated to matching or beating the prices of eligible competitors to give you peace of mind when making your travel arrangements.</p>
            <h3>How It Works:</h3>
            <p><strong>1.	Eligibility:</strong> Our Price Match Policy applies to bookings made directly through Cloud Faresfor identical travel products and services.<br />
              <strong>2.	Competitor Eligibility:</strong> We will match or beat the price of eligible competitors who offer the same travel products, services, and inclusions. Competitors must be authorized travel agencies or websites and must adhere to ethical business practices.<br />
              <strong>3.	Comparison Criteria:</strong> To be eligible for a price match, the following criteria must be met:
            </p>
            <ul>
              <li>The product or service must be identical, including the same travel dates, destinations, airlines, accommodations, room types, and inclusions.</li>
              <li>The lower price must be publicly advertised and verifiable.</li>
              <li>The lower price must be available for booking at the time of the price match request.</li>
            </ul>
            <p><strong>4.	Requesting a Price Match:</strong> To request a price match, follow these steps:</p>
            <ul>
              <li>Contact our customer service team with the details of the lower-priced offer, including the competitor's name, website, and a link to the offer.</li>
              <li>Provide all relevant booking details, including travel dates, destinations, and product or service specifics.</li>
            </ul>
            <p><strong>5.	Verification and Approval:</strong> Our customer service team will review your price match request and verify the information provided. If your request meets the eligibility criteria, we will match or beat the competitor's price.</p>
            <p><strong>6.	Matching the Price:</strong> If your price match request is approved:</p>
            <ul>
              <li>For existing bookings: We will adjust the price of your booking to match the competitor's lower price.</li>
              <li>	For new bookings: We will offer you the lower price at the time of booking.</li>
            </ul>
            <h3>Exclusions:</h3>
            <ul>
              <li>Our Price Match Policy does not apply to package deals, promotional offers, bundled products, or products with limited availability.</li>
              <li>We do not match prices on bookings made through auction sites, opaque websites, or loyalty reward programs.</li>
            </ul>
            <h3>Terms and Conditions:</h3>
            <ul>
              <li>Our Price Match Policy is subject to change without notice.</li>
              <li>We reserve the right to verify the details of the competitor's offer and to refuse a price match request that does not meet our eligibility criteria.</li>
            </ul>
            <p>At Cloud Fares, we believe in transparency and providing you with a hassle-free booking experience. Our Price Match Policy is a testament to our commitment to ensuring you get the best deals on your travel arrangements.</p>
            <p>For inquiries or to request a price match, please contact our customer service team at info@cloudfares.co.uk</p>

          </div>
        </div>
      </div>
    </>
  )
}

export default PriceMatchPolicy
