import React from 'react'

function CallToBook() {
  return (
    <>
    <div className='CalltoBook'>
      <h1>Flight Booking Enquiry</h1>
      <p>We need your details to reserve selected flight!</p>
      <h2>Call To Book <a href='tel: 0208 095 2365'> 0208 095 2365</a></h2>
      </div>
    </>
  )
}

export default CallToBook
