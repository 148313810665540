import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const ToAirport = ({ onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Fetch airport options from the API
    axios.get('https://peonytrips.com/FlightFare/ToAirportAPI')
      .then(response => {
        const airportOptions = response.data.map(airport => ({
          value: airport.IATACode,
          label: (
            <span className='airportname'>
              {`${airport.AirportName} (${airport.IATACode})`} 
              <span>{airport.CountryName}</span>
            </span>
          )
        }));
        setOptions(airportOptions);
      })
      .catch(error => {
        console.error('Error fetching airport data:', error);
      });
  }, []);

  return (
    <div>
      <Select required options={options} onChange={onChange} className="form-control" name='Destination' />
    </div>
  );
};

export default ToAirport;
