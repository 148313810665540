import React from 'react'

function Subscribe() {
  return (
    <>
      <section className="newssec">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <h3>EXPLORE THE WORLD WITH US </h3>
        <p>Check our discounted, Unpublished and last-minute deals on airfares</p>
        <form method="post" className="form" id="form-1694266465007" novalidate="novalidate">
          <div className="input-group">
            <input type="text" className="form-control" placeholder="Enter Your Email Address" name="Email" required="" />
            <div className="input-group-append">
              <button className="btn btn-subscribe" type="submit" name="submit" value="subscribe">Subscribe Now</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default Subscribe
