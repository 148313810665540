import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom'; // Import NavLink
import Logo from "../images/img/cloudfares.png";
import CallIcon from "../images/img/HeaderCallNo.png";

function Header() {
  const location = useLocation();
  const isAirlinePage = location.pathname.startsWith('/business-class/') || location.pathname.startsWith('/airlines/') || location.pathname.startsWith('/first-class/');
  return (
    <>
    <header className={`header ${isAirlinePage ? 'airlineHeader' : ''}`}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <Link to='/'><img src={Logo} alt="Airlines Tickets" className='hlogo' /></Link>
          </div>
          <div className='col-md-5'></div>
          <div className='col-md-4 mob'>
            <a href='tel:+44 0208 095 2365'><img src={CallIcon} alt="Airlines Tickets" /></a>
          </div>
        </div>
      </div>

      <section className='header'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
          <ul>
                <li><NavLink exact to='/' activeClassName='active'>HOME</NavLink></li>
                {/* <li><NavLink to='/airlines' activeClassName='active'>AIRLINES</NavLink></li> */}
                <li><NavLink to='/continents' activeClassName='active'>DESTINATIONS</NavLink></li>
                {/* <li><NavLink to='/business-class' activeClassName='active'>BUSINESS CLASS</NavLink></li>
                <li><NavLink to='/first-class' activeClassName='active'>FIRST CLASS</NavLink></li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
      </header>
    </>
  );
}

export default Header;
