import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from "../images/img/footercard.png";
import SSL from "../images/img/ssl.png";
import CallIcon from "../images/icon/CallIcon.png";
import ReactGA from 'react-ga';
function Footer() {
  const [isVisible, setIsVisible] = useState(true);

  const handleHideClick = () => {
    setIsVisible(false);
  };

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const timerX = setInterval(() => {
      if (window.location.href.indexOf('/Thanks/') !== -1) {
        if (x === 0) {
          ReactGA.event({
            category: 'conversion',
            action: 'send_to',
            value: 'AW-11266740148/J2cKCIKBytMYELSfs_wp',
          });
          setX(1);
        }
        clearInterval(timerX);
      }
    }, 1000);

    const timerY = setInterval(() => {
      if (window.location.pathname === '/') {
        if (y === 0) {
          ReactGA.event({
            category: 'conversion',
            action: 'send_to',
            value: 'AW-11266740148/Tp9UCKDI1NMYELSfs_wp',
          });
          setY(1);
        }
        clearInterval(timerY);
      }
    }, 1000);

    return () => {
      clearInterval(timerX);
      clearInterval(timerY);
    };
  }, [x, y]);
  
  return (
    <> 
      <footer className="footercover">
        <div className="container">
          <div className="row">
            <div className='col-md-4 footer-contact w50'>
              <h2>Cloud Fares</h2>
              <ul>
<li><Link to="/">Home</Link> </li>
{/* <li><Link to="/airlines">Airlines</Link> </li> */}
<li><Link to="/continents">Destinations</Link></li>
{/* <li><Link to="/business-class">Business Class</Link></li>
<li><Link to="/first-class">First Class</Link></li> */}

            </ul> 
            </div>
            <div className='col-md-4 footer-contact w50'>
              {/* <h2>Airlines Tickets</h2> */}
            <ul>
<li><Link to="/privacy-policy">Privacy Policy</Link> </li>
<li><Link to="/term-conditions">Terms And Conditions</Link> </li>
<li><Link to="/price-match-policy">Price Match Policy</Link></li>
<li><Link to="/contact-us">Contact Us</Link></li>
 

            </ul> 
            </div>
            {/* <div className='col-md-3 footer-contact w50'>
              <h2>We Accept</h2>
              <ul>
                <li className="sslLogo"><img src={Card} alt='Card' /></li>
                <li className="sslLogo"><img src={SSL} alt='SSL' /></li>
                <li>Online Secure</li>
                <li>Book Now Pay Later</li>
               
              </ul>
            </div> */}
            <div className='col-md-3 footer-contact w50'>
              <h2> </h2>
              <ul>
                <li><Link to="/FAQ">FAQ's</Link></li>
               
                 <li><a href='https://tawk.to/chat/64f0611ea91e863a5c10d4b3/1h95fmofp' target='_blank'> Live Chat</a> </li>
                {/* <li>Online Payment</li> */}
               
              </ul>
            </div>
          </div>

        </div>

      </footer>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p><strong>Disclaimer:</strong> Cloud Fares is an independent travel service provider that provides cost effective travel services. We do not claim that we are Airlines. Brands displayed on our pages are owned by the respective brand owners. <br />
              In case any content of the website is violating the privacy of any other website you can report us on info@cloudfares.co.uk<br />
              Copyright@2024 cloudfares.co.uk. All rights reserved.
              </p>
            </div>

          </div>
        </div>
      </div>
      <div className='mobileno'>
     <div className='callIcon'><a href='tel:+44 0208 095 2365'><img src={CallIcon} alt="+44 0208 095 2365" /></a></div>
       
     </div>
    {isVisible &&<div className="mobilePopup" align="center">
	<div className="container">
		<div id="mobclose" onClick={handleHideClick}><img src="https://www.cloudfares.co.uk/popup/close.png" /></div>
	<div className="row">
		<div className="col-md-12 airLogo"><h2>Call Us for Special Flight Deals</h2><a href="tel: +44 0208 095 2365">24/7 Customer Support &amp; Reservations</a></div>
		<div className="col-md-12 bookingbtn"><a href="tel: +44 0208 095 2365">Flight Booking</a>
			<a href="tel: +44 0208 095 2365">Ticket Changes</a> 
			 <a href="tel: +44 0208 095 2365">Customer Support</a>
       <a href="tel: +44 0208 095 2365">Cancellation</a> </div>
		<div className="col-md-12 mb20"><img src="https://www.cloudfares.co.uk/popup/QRF.png" /><br /> <br /><img src="https://www.cloudfares.co.uk/popup/girl-mfp.png" /><br /></div>
		<div className="col-md-12"></div>
		<div className="col-md-12 airLogo"><a href="tel: +44 0208 095 2365">Call Answered within second</a></div>
		<div className="col-md-12">
		<div className="callicons"> <a href="tel: +44 0208 095 2365" className="circlePhone"></a>
   <a href="tel:+44 0208 095 2365"> +44 0208 095 2365</a>
</div>
			<div className="col-md-12 airLogo"><br /><a href="tel: +44 0208 095 2365">24/7 Call Support </a> </div>
			
		</div>
		</div>
	</div>
</div>}
    </>
  )
}

export default Footer
