import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";
import Header from "./Include/Header";
import ScrollTop from './Include/ScrollTop';
import Footer from "./Include/Footer";
import Home from "./Company/Home";
import About from "./Company/About";
import Airlines from "./Airline/Airlines";
import AirCanadaAirline from "./Airline/AirCanadaAirline";
import AirFranceAirline from "./Airline/AirFranceAirline";
import AirIndiaAirline from "./Airline/AirIndiaAirline";
import AirMauritiusAirline from "./Airline/AirMauritiusAirline";
import AmericanAirline from "./Airline/AmericanAirline";
import BritishAirwaysAirline from "./Airline/BritishAirwaysAirline";
import BrusellsAirlinesAirline from "./Airline/BrusellsAirlinesAirline";
import CathayPacificAirline from "./Airline/CathayPacificAirline";
import EgyptAirAirline from "./Airline/EgyptAirAirline";
import EmiratesAirline from "./Airline/EmiratesAirline";
import EthiopianAirline from "./Airline/EthiopianAirline";
import EtihadAirline from "./Airline/EtihadAirline";
import GulfAirwaysAirline from "./Airline/GulfAirwaysAirline";
import IberiaAirline from "./Airline/IberiaAirline";
import JapanAirlnesAirline from "./Airline/JapanAirlnesAirline";
import KenyaAirline from "./Airline/KenyaAirline";
import KlmAirline from "./Airline/KlmAirline";
import KuwaitAirline from "./Airline/KuwaitAirline";
import LufthansaAirline from "./Airline/LufthansaAirline";
import MalaysiaAirline from "./Airline/MalaysiaAirline";
import MiddleEastAirlinesAirline from "./Airline/MiddleEastAirlinesAirline";
import OmanAirline from "./Airline/OmanAirline";
import QantasAirline from "./Airline/QantasAirline";
import QatarAirline from "./Airline/QatarAirline";
import RoyalAirMarocAirline from "./Airline/RoyalAirMarocAirline";
import RoyalJordanianAirline from "./Airline/RoyalJordanianAirline";
import RwandAirAirline from "./Airline/RwandAirAirline";
import SaudiAirline from "./Airline/SaudiAirline";
import SingaporeAirline from "./Airline/SingaporeAirline";
import SrilankanAirline from "./Airline/SrilankanAirline";
import TapPortugalAirline from "./Airline/TapPortugalAirline";
import ThaiAirline from "./Airline/ThaiAirline";
import TurkishAirline from "./Airline/TurkishAirline";
import UnitedAirline from "./Airline/UnitedAirline";
import VirginAirline from "./Airline/VirginAirline";
import WestJetAirline from "./Airline/WestJetAirline";

import FirstClass from "./Airline/FirstClass";
import BusinessClassAirlines from "./Airline/BusinessClassAirlines";
import BusinessClassAirFrance from "./Airline/BusinessClassAirFrance";
import BusinessClassAirNewZealand from "./Airline/BusinessClassAirNewZealand";
import BusinessClassBritishAirways from "./Airline/BusinessClassBritishAirways";
import BusinessClassCathayPacific from "./Airline/BusinessClassCathayPacific";
import BusinessClassDeltaAirline from "./Airline/BusinessClassDeltaAirline";
import BusinessClassEmirates from "./Airline/BusinessClassEmirates";
import BusinessClassEtihad from "./Airline/BusinessClassEtihad";
import BusinessClassEVAAirline from "./Airline/BusinessClassEVAAirline";
import BusinessClassKLMAirline from "./Airline/BusinessClassKLMAirline";
import BusinessClassMalaysianAirlines from "./Airline/BusinessClassMalaysianAirlines";
import BusinessClassQatarAirline from "./Airline/BusinessClassQatarAirline";
import BusinessClassSingaporeAirlines from "./Airline/BusinessClassSingaporeAirlines";
import BusinessClassSwiss from "./Airline/BusinessClassSwiss";
import BusinessClassUnitedAirlines from "./Airline/BusinessClassUnitedAirlines";
import BusinessClassVirginAtlantic from "./Airline/BusinessClassVirginAtlantic";
import BusinessClassThaiAirways from "./Airline/BusinessClassThaiAirways";
import Continents from "./Destinations/Continents";
import Country from "./Destinations/Country";
import TermConditions from "./Company/TermConditions";
import FAQ from "./Company/FAQ";
import PrivacyPolicy from "./Company/PrivacyPolicy";
import PriceMatchPolicy from "./Company/PriceMatchPolicy";
import Contact from "./Company/Contact";
import CallToBook from "./Include/CallToBook";
import Thanks from "./Include/Thanks";

import SearchEng from "./Include/SearchEng";
import AirlineSearchEng from "./Include/AirlineSearchEng";
import SearchFlight from "./Include/SearchFlight";
import FlightDetails from "./Include/FlightDetails";
import CountryDetail from "./Destinations/CountryDetail";
 
const App = () => {
  const [flightResults, setFlightResults] = useState([]);

  useEffect(() => {
    // Fetch flight data from the API using axios
    axios.get('https://peonytrips.com/FlightFare/FlightFareAPI')
      .then(response => {
        setFlightResults(response.data);
      })
      .catch(error => {
        console.error('Error fetching flight data:', error);
      });
  }, []);
  
  return (
    <>
      <Router>
      <ScrollTop />     
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          {/* <Route exact path='/airlines' element={<Airlines />} />
           <Route exact path='/airlines/air-canada' element={<AirCanadaAirline />} />
           <Route exact path='/airlines/air-france' element={<AirFranceAirline />} />
           <Route exact path='/airlines/air-india' element={<AirIndiaAirline />} />
           <Route exact path='/airlines/air-mauritius' element={<AirMauritiusAirline />} />
           <Route exact path='/airlines/american-airlines' element={<AmericanAirline />} />
           <Route exact path='/airlines/british-airways' element={<BritishAirwaysAirline />} />
           <Route exact path='/airlines/brussels-airlines' element={<BrusellsAirlinesAirline />} />
           <Route exact path='/airlines/cathay-pacific' element={<CathayPacificAirline />} />
           <Route exact path='/airlines/egypt-air' element={<EgyptAirAirline />} />
           <Route exact path='/airlines/emirates' element={<EmiratesAirline />} />
           <Route exact path='/airlines/ethiopian-airlines' element={<EthiopianAirline />} />
           <Route exact path='/airlines/etihad-airways' element={<EtihadAirline />} />
           <Route exact path='/airlines/gulf-air' element={<GulfAirwaysAirline />} />
           <Route exact path='/airlines/iberia-airline' element={<IberiaAirline />} />
           <Route exact path='/airlines/japan-airlnes' element={<JapanAirlnesAirline />} />
           <Route exact path='/airlines/kenya-airways' element={<KenyaAirline />} />
           <Route exact path='/airlines/klm-airline' element={<KlmAirline />} />
           <Route exact path='/airlines/kuwait-airways' element={<KuwaitAirline />} />
           <Route exact path='/airlines/lufthansa-airline' element={<LufthansaAirline />} />
           <Route exact path='/airlines/malaysian-airlines' element={<MalaysiaAirline />} />
           <Route exact path='/airlines/middleeast-airlines' element={<MiddleEastAirlinesAirline />} />
           <Route exact path='/airlines/oman-air' element={<OmanAirline />} />
           <Route exact path='/airlines/qantas-airways' element={<QantasAirline />} />
           <Route exact path='/airlines/qatar-airways' element={<QatarAirline />} />
           <Route exact path='/airlines/royal-air-maroc-airline' element={<RoyalAirMarocAirline />} />
           <Route exact path='/airlines/royal-jordanian-airlines' element={<RoyalJordanianAirline />} />
           <Route exact path='/airlines/rwandair' element={<RwandAirAirline />} />
           <Route exact path='/airlines/saudi-arabian-airlines' element={<SaudiAirline />} />
           <Route exact path='/airlines/singapore-airlines' element={<SingaporeAirline />} />
           <Route exact path='/airlines/srilankan-airlines' element={<SrilankanAirline />} />
           <Route exact path='/airlines/tap-air-portugal' element={<TapPortugalAirline />} />
           <Route exact path='/airlines/thai-airways' element={<ThaiAirline />} />
           <Route exact path='/airlines/turkish-airlines' element={<TurkishAirline />} />
           <Route exact path='/airlines/united-airlines' element={<UnitedAirline />} />
           <Route exact path='/airlines/virgin-atlantic' element={<VirginAirline />} />
           <Route exact path='/airlines/westjet-airlines' element={<WestJetAirline />} />
          <Route exact path='/first-class' element={<FirstClass />} />
          <Route exact path='/business-class' element={<BusinessClassAirlines />} />         
<Route exact path='/business-class/air-france' element={<BusinessClassAirFrance />} />
<Route exact path='/business-class/air-newzealand' element={<BusinessClassAirNewZealand />} />
<Route exact path='/business-class/british-airways' element={<BusinessClassBritishAirways />} />
<Route exact path='/business-class/cathay-pacific' element={<BusinessClassCathayPacific />} />
<Route exact path='/business-class/delta-airlines' element={<BusinessClassDeltaAirline />} />
<Route exact path='/business-class/emirates' element={<BusinessClassEmirates />} />
<Route exact path='/business-class/etihad-airways' element={<BusinessClassEtihad />} />
<Route exact path='/business-class/eva-air' element={<BusinessClassEVAAirline />} />
<Route exact path='/business-class/klm-airlines' element={<BusinessClassKLMAirline />} />
<Route exact path='/business-class/malaysian-airlines' element={<BusinessClassMalaysianAirlines />} />
<Route exact path='/business-class/qatar-airways' element={<BusinessClassQatarAirline />} />
<Route exact path='/business-class/singapore-airlines' element={<BusinessClassSingaporeAirlines />} />
<Route exact path='/business-class/swiss-airlines' element={<BusinessClassSwiss />} />
<Route exact path='/business-class/united-airlines' element={<BusinessClassUnitedAirlines />} />
<Route exact path='/business-class/virgin-atlantic' element={<BusinessClassVirginAtlantic />} />
<Route exact path='/business-class/thai-airways' element={<BusinessClassThaiAirways />} /> */}



          <Route exact path='/about-us' element={<About />} />
          <Route exact path='/term-conditions' element={<TermConditions />} />
          <Route exact path='/FAQ' element={<FAQ />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/price-match-policy' element={<PriceMatchPolicy />} />
          <Route exact path='/contact-us' element={<Contact />} />
          <Route exact path='/CallToBook' element={<CallToBook />} />
          <Route exact path='/Thanks' element={<Thanks />} />
          <Route path="*" element={<Thanks />} />
          <Route path="/" element={<SearchEng />} />
        {/* <Route path="/SearchFlight" element={<SearchFlight />} />
        <Route path="/flight-details/:flightId" component={FlightDetails} /> */}
         <Route path="/SearchFlight" element={<SearchFlight flightResults={flightResults} />} />
          <Route path="/flight-details/:flightId" element={<FlightDetails flightResults={flightResults} />} />
          <Route exact path='/continents' element={<Continents />} /> 
          <Route path="/destinations/:continentName" element={<Country />} />
          <Route path="/destinations/:continentName/:countryName" element={<CountryDetail />} />
        </Routes>
        <Footer />
      </Router>
    </>
   
   );
  }
  
  function RedirectToThanks() {
    const navigate = useNavigate(); // Use the useNavigate hook
    navigate('/Thanks'); // Redirect using the navigate function
    return null;
  }
  export default App;
