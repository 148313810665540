import React from 'react';
import { useParams } from 'react-router-dom';

const FlightDetails = ({ flightResults }) => {
  const { flightId } = useParams();
  console.log('flightId:', flightId); // Add this log

  const parsedFlightId = parseInt(flightId, 10);
  console.log('parsedFlightId:', parsedFlightId); // Add this log

  const selectedFlight = flightResults.find(result => result.id === parsedFlightId);
  console.log('selectedFlight:', selectedFlight); // Add this log

  if (!selectedFlight) {
    return <div>Flight not found</div>;
  }

  return (
    <div>
      <h1>Flight Details</h1>
      <div>
        <h2>Airline: {selectedFlight.AirlinesName}</h2>
        <p>Source: {selectedFlight.Source}</p>
        <p>Destination: {selectedFlight.Destination}</p>
        {/* Add more flight details */}
      </div>
    </div>
  );
};

export default FlightDetails;
