import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import farePlane from "../images/img/farePlane.png";
import Carousel from 'react-bootstrap/Carousel';
import continents from "../images/img/continents.jpg";
import SearchEng from '../Include/SearchEng';
function CountryDetail() {
    const { continentName, countryName } = useParams();
    const [airportPrices, setAirportPrices] = useState([]);

    useEffect(() => {
        // Fetch data based on the selected continent and country
        fetch(`https://peonytrips.com/FlightFare/DestinationsAPI?continent=${continentName}&country=${countryName}`)
            .then(response => response.json())
            .then(data => {
                // Filter destinations for the specific country and Economy class
                const filteredEconomyDestinations = data.filter(
                    destination => destination.Continents === continentName && destination.ToCountry === countryName && destination.Class === 'Economy'
                );

                // Create a map to track unique airports and their lowest prices
                const uniqueAirports = new Map();

                filteredEconomyDestinations.forEach(destination => {
                    const airport = destination.ToAirportName;
                    const price = destination.Price;

                    if (!uniqueAirports.has(airport) || price < uniqueAirports.get(airport)) {
                        uniqueAirports.set(airport, price);
                    }
                });

                // Convert the map back to an array for rendering
                const uniqueAirportsArray = Array.from(uniqueAirports, ([airport, price]) => ({ airport, price }));
                
                // Sort the array by price
                uniqueAirportsArray.sort((a, b) => a.price - b.price);

                setAirportPrices(uniqueAirportsArray);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [continentName, countryName]);

    if (airportPrices.length === 0) {
        return <div className='dataloading'><img src='https://www.airlines-ticketsdesk.com/popup/dataload.gif' /></div>;
    }
    return (
        <>
        <div className='container-fluid p0'>
        <div className='row'>
          <div className='col-md-12 homeSearch'>
            <Carousel>
              <Carousel.Item>
                <img src={continents} alt="" />
              </Carousel.Item>

            </Carousel>
            <div className='container myflight'>
              <SearchEng />

            </div>
          </div>
        </div>
      </div>
        <div className='container headerPage'>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>{countryName} City Fares</h2>
                    <h3>Search & See regular fare deals and save on your short haul and visit tourist places.</h3>
                </div>
            </div>
            <div className='row'>
                {airportPrices.map(destination => (
                    <div className="col-lg-4 col-xl-3 col-md-6" key={destination.airport}>
                        <a href="tel: 0208 642 4456"> 
                        <div className="city-fare-box"> <span>cheap fares</span>
                            <p>London All to</p>
                            <h4>{destination.airport}</h4>
                            <img src={farePlane} alt="Plane" className="img-fluid" />
                            <h3><sub>£</sub>{destination.price}</h3>
                        </div>
                         </a>
                    </div>
                   
                ))}
            </div>
        </div>
        </>
    );
}

export default CountryDetail;