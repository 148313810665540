import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import holidayFlight from "../images/img/holidayFlight.jpg";

const SearchFlight = () => {
  const location = useLocation();
  const flightResults = location.state || [];

  const [filteredCarriers, setFilteredCarriers] = useState([]);
  const [filteredSources, setFilteredSources] = useState([]);
  const [filteredAdults, setFilteredAdults] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);

  useEffect(() => {
    const prices = flightResults.map(result => parseInt(result.Adults, 10));
    const newMinPrice = Math.min(...prices);
    const newMaxPrice = Math.max(...prices);

    setMinPrice(newMinPrice);
    setMaxPrice(newMaxPrice);
    setPriceRange([newMinPrice, newMaxPrice]);
  }, [flightResults]);

  const handleCarrierFilter = (carrierName) => {
    if (filteredCarriers.includes(carrierName)) {
      setFilteredCarriers(filteredCarriers.filter(carrier => carrier !== carrierName));
    } else {
      setFilteredCarriers([...filteredCarriers, carrierName]);
    }
  };

  const handleSourceFilter = (source) => {
    if (filteredSources.includes(source)) {
      setFilteredSources(filteredSources.filter(src => src !== source));
    } else {
      setFilteredSources([...filteredSources, source]);
    }
  };

  const handleAdultsFilter = (adults) => {
    if (filteredAdults.includes(adults)) {
      setFilteredAdults(filteredAdults.filter(adult => adult !== adults));
    } else {
      setFilteredAdults([...filteredAdults, adults]);
    }
  };

  const handleClassFilter = (flightClass) => {
    if (filteredClasses.includes(flightClass)) {
      setFilteredClasses(filteredClasses.filter(cls => cls !== flightClass));
    } else {
      setFilteredClasses([...filteredClasses, flightClass]);
    }
  };

  const handlePriceFilter = (newRange) => {
    setPriceRange(newRange);
  };

  const airlinesList = Array.from(new Set(flightResults.map(result => result.Carrier)));
  const sourcesList = Array.from(new Set(flightResults.map(result => result.Source)));
  const adultsList = Array.from(new Set(flightResults.map(result => result.Adults)));
  const availableClasses = Array.from(new Set(flightResults.map(result => result.Class)));

  const filteredResults = flightResults.filter(result => {
    const priceInRange =
      parseInt(result.Adults, 10) >= priceRange[0] && parseInt(result.Adults, 10) <= priceRange[1];

    return (
      (filteredCarriers.length === 0 || filteredCarriers.includes(result.Carrier)) &&
      (filteredSources.length === 0 || filteredSources.includes(result.Source)) &&
      (filteredAdults.length === 0 || filteredAdults.includes(result.Adults.toString())) &&
      (filteredClasses.length === 0 || filteredClasses.includes(result.Class)) &&
      priceInRange
    );
  });

  const isLoading = flightResults.length === 0;


  return (
    <>
      <div className='container aipage'>
        <div className='row'>
        <div className='col-md-3'>
            <div className='filterbox'>
              <h2>Sort & Filter</h2>
               {/* Class filter */}
               <h3>Select Class</h3>
              {availableClasses.map((flightClass, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={filteredClasses.includes(flightClass)}
                    onChange={() => handleClassFilter(flightClass)}
                  />
                  {flightClass}
                </label>
              ))}
              <hr />
              {/* Carrier filter */}
              <h3>Select Airline</h3>
              {airlinesList.map((carrier, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={filteredCarriers.includes(carrier)}
                    onChange={() => handleCarrierFilter(carrier)}
                  />
                  {flightResults.find(result => result.Carrier === carrier)?.AirlinesName}
                </label>
              ))}
              <hr />
              
              {/* Price range filter */}
              <h3>Select Price Range</h3>
              <div className='prange'>
                <label>Price Range</label>
                <span>&pound; {priceRange[0]}</span> - <span>&pound; {priceRange[1]}</span>
                <input
                  type="range"
                  min={minPrice}
                  max={maxPrice}
                  step="10"
                  value={priceRange[1]}
                  onChange={(e) => handlePriceFilter([priceRange[0], parseInt(e.target.value, 10)])}
                />
              </div>
              <hr />
              {/* Source filter */}
              <h3>Select From Airport</h3>
              {sourcesList.map((source, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={filteredSources.includes(source)}
                    onChange={() => handleSourceFilter(source)}
                  />
                  {flightResults.find(result => result.Source === source)?.FrAirportName}
                </label>
              ))}
              <hr />
              {/* Adult filter */}
              {/* <h3>Select Adults</h3>
              {adultsList.map((adults, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={filteredAdults.includes(adults)}
                    onChange={() => handleAdultsFilter(adults)}
                  />
                  {adults} Adults
                </label>
              ))}
              <hr /> */}
             
            </div>
          </div>
          <div className='col-md-9'>
            <div className='col-md-12 resmsg'>
              <h2>
                <i className='fa fa-plane'></i> Search Flight From{' '}
                {flightResults.length > 0 ? (
                  <p>
                    <span>{flightResults[0].FrAirportName}</span> to{' '}
                    <span>{flightResults[0].ToAirportName}</span>
                  </p>
                ) : (
                  <p>
                    <Link to="/" className="btn book-now">Search again</Link>
                  </p>
                )}
              </h2>
            </div>
        {filteredResults.length > 0 ? (
              // Display filtered results
              filteredResults.map((result, index) => (
                <div className='fare-list' key={index}>
                <div className="fare-top-bar">
                <div className="row">
                  <div className="col-md-9 order-md-0 order-sm-1 order-1">
                    <div className="flight-fares-repeat">
                      <div className="oneway-flight">
                        <div className="airline-link">
                          <div className="circle"> <img src={`https://peonytrips.com/FlightFare/airlinelogo/${result.Carrier}.gif`} alt={result.Carrier} /></div>
                          <p>{result.AirlinesName} </p>
                        </div>
                        <div className="flight-city">
                          <h3>{result.Source}</h3>
                          <span>{result.FrAirportName} ({result.Source})</span>
                          <p>{result.FrCountry}</p>
                        </div>
                        <div className="stop-time">
                          <div className="stop-img-cover">
                            <div className="dot"></div>
                            <div className="stop-img"> </div>
                            <div className="dot"></div>
                          </div>
                        </div>
                        <div className="flight-city">
                          <h3>{result.Destination} </h3>
                          <span>{result.ToAirportName} ({result.Destination})</span>
                          <p>{result.ToCountry}</p>
                        </div>
                      </div>
                    </div>
                    <div className="fare-bottom-bar">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="fares-btn">
                            <div className="airlines-number">Call Us : <a href="tel:0208 642 4456
">0208 642 4456 </a> </div>
                            <div className="airlines-category">
                              <p>Return {result.Class}</p>
                            </div>
                            <div className="others-options">
                            {/* <Link to={`/flight-details/${result.id}`} className="btn book-now">
            Enquire Now
          </Link> */}
         <a href="tel: 0208 642 4456" className="btn book-now">
           Call To Book
          </a>

                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 order-md-1 order-sm-0 order-0">
                    <div className="fare-per-flight position-relative">
                      <h4><sup>fr</sup>£{result.Adults}</h4>
                      <p>Including All Taxes</p>
                      <p>Per Person</p>
                      <p>Subject to Availability</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            ))
          ) : (
            <div className='no-results'>
             <div className='CalltoBook'>
    <h1>Thank You for Choosing Cloud Fares for Your Travel Needs!</h1>
    <p>We appreciate your trust in us to handle your travel arrangements. Whether you're planning a relaxing vacation, an adventurous getaway, or a business trip, we're dedicated to providing you with top-notch service and seamless travel experiences.</p>
    <Link to="/"><img src={holidayFlight} alt="Airlines Tickets" /></Link>
    <h3>Contact Us</h3>
    <p>Got a question or need assistance? Our friendly team is here to help!</p>
 
    <h2><strong>Customer Support: </strong><a href='tel: 0208 095 2365'>0208 095 2365 </a><br /> 
    <strong>Email: </strong> <a href='mailto:info@cloudfares.co.uk'>info@cloudfares.co.uk</a>
</h2>
    </div>

            </div>
          )}
        </div>
      </div>
    </div>
  </>
);
};

export default SearchFlight;